<template>
    <div>
      <div v-for="(message, index) in messages" :key="index" class="balloon-left">
        <template v-if="message.type === 'text'">
          <template v-if="hasContents(message)">
            <div class="avatar">
              <v-avatar color="grey lighten-2">
                <v-icon dark large>mdi-account</v-icon>
              </v-avatar>
            </div>
            <div row>
              <p class="username">{{ loginUser.last_name + loginUser.first_name }}</p>
              <p :id="'textmessage' + index" class="text-preview"></p>
            </div>
          </template>
        </template>
        <template v-if="message.type === 'image'">
          <template v-if="hasContents(message)">
            <div class="avatar">
              <v-avatar color="grey lighten-2">
                <v-icon dark large>mdi-account</v-icon>
              </v-avatar>
            </div>
            <div row>
              <p class="username">{{ loginUser.last_name + loginUser.first_name }}</p>
              <v-img class="image-preview" :src="message.previewImageUrl" max-width="180" contain> </v-img>
            </div>
          </template>
        </template>
        <template v-if="message.type === 'sticker'">
          <template v-if="hasContents(message)">
            <div class="avatar">
              <v-avatar color="grey lighten-2">
                <v-icon dark large>mdi-account</v-icon>
              </v-avatar>
            </div>
            <div row>
              <p class="username">{{ loginUser.last_name + loginUser.first_name }}</p>
              <v-img class="image-preview" :src="'https://stickershop.line-scdn.net/stickershop/v1/sticker/' + message.stickerId + '/android/sticker.png'" max-width="180" contain> </v-img>
            </div>
          </template>
        </template>
        <!-- imagemapは未対応の未実装 -->
        <template v-if="message.type === 'imagemap'">
          <template v-if="hasContents(message)">
            <div class="avatar">
              <v-avatar color="grey lighten-2">
                <v-icon dark large>mdi-account</v-icon>
              </v-avatar>
            </div>
            <div row>
              <p class="username">{{ loginUser.last_name + loginUser.first_name }}</p>
              <v-img class="image-preview" :src="imagemapUrl(message.contents.imagemapRenderingPayload.baseUrl)" max-width="180" contain> </v-img>
            </div>
          </template>
        </template>
        <template v-if="message.type === 'flex'">
          <template v-if="hasContents(message)">
            <div class="avatar">
              <v-avatar color="grey lighten-2">
                <v-icon dark large>mdi-account</v-icon>
              </v-avatar>
            </div>
            <div row class="flex-preview">
              <p class="username">{{ loginUser.last_name + loginUser.first_name }}</p>
                <template>
                  <BubbleFlexPreview :title="title" :isFirstFlex="isFirstFlex(messages, index)" :message="message.flexMessage"/>
                </template>
            </div>
          </template>
        </template>
        <!-- videoは未対応の未実装 -->
        <template v-if="message.type === 'video'">
          <template v-if="hasContents(message)">
            <div class="avatar">
              <v-avatar color="grey lighten-2">
                <v-icon dark large>mdi-account</v-icon>
              </v-avatar>
            </div>
            <div row>
              <p class="username">{{ loginUser.last_name + loginUser.first_name }}</p>
              <v-img class="image-preview" :src="message.contents.videoThumbModel.getObjectURL()" max-width="180" contain> </v-img>
            </div>
          </template>
        </template>
      </div>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import { mapState } from "vuex";
  import BubbleFlexPreview from "../fragments/BubbleFlexPreview.vue";
  
//   const RENDER_COMPONENTS = {
//     text: "text",
//     image: "image",
//   };
  
//   interface LocalState {
//     elTextMessage: any;
//   }
  
  export default Vue.extend({
    name: "TalkRendering",
    components: { BubbleFlexPreview },
    mixins: [],
    props: {
      messages: Array,
      positionInfo: Array,
      preview: Boolean,
      title: String,
    },
    computed: {
      ...mapState({
        userStore: (state) => state.auth.user,
        loginUser: (state) => state.loginUser,
      }),
    },
    data() {
      return {
        elTextMessage: "",
      };
    },
    watch: {
      preview: function(value) {
        if (value) {
          this.synchronizeEmoji();
        }
      },
      positionInfo: function() {
        this.synchronizeEmoji();
      },
      title() {
        this.synchronizeEmoji();
      }
    },
    methods: {
      isFirstFlex(message, index) {
        let firstMsg = null
        for(let i = 0; i < message.length; i ++) {
          if (message[i].type == 'text' || message[i].type == 'flex') {
            firstMsg = i
            break
          } 
        }
        return firstMsg === Number(index) ? true : false
      },
      hasContents(value) {
        switch (value.type) {
          case "text":
            return value.text.length !== 0 && value.text.trim().length !== 0;
          case "image":
            return value.previewImageUrl.length !== 0;
          case "sticker":
            return value.packageId.length !== 0 && value.stickerId.length !== 0;
          case "imagemap":
            return value.imagemapRenderingPayload && value.imagemapRenderingPayload.baseUrl;
          case "flex":
            // flexMessageが存在するかチェック
            if (value.flexMessage) {
              // keyが存在するかチェック
              return Object.keys(value.flexMessage).length !== 0;
            }
            return false;
          case "video":
            return value.videoModel && value.videoThumbModel;
        }
      },
      imagemapUrl(baseUrl) {
        return baseUrl.startsWith("data:") ? baseUrl : baseUrl + '/1040'
      },
      createTextMessage() {
        const size = 27;
        // タイトルを入れるかどうかのフラグ
        // メッセージ配列の中で、最初のテキストメッセージまたはフレックスメッセージに
        let joinTitleFlag = 0
        this.messages.forEach((message,i) => {
          if(message.type=="flex"){
            joinTitleFlag = 1
          }
          if(message.type=="text"){
            this.elTextMessage = document.getElementById('textmessage' + i);
            if (!this.elTextMessage) {
              return;
            }
            this.elTextMessage.innerHTML = "";
            
            let chars = null

            if(joinTitleFlag == 0 && (this.title != '' && this.title != null && this.title != undefined)) {
              let titleChars = (this.title + '\n\n').split("");

              let msgChars = message.text.split("");

              chars = titleChars.concat(msgChars)
            } else {
              chars = message.text.split("");
            }
            
            let index = 0;
            this.positionInfo[i].emojis.forEach(emoji => {
                const text = chars.slice(index, emoji.index);
                this.elTextMessage.appendChild(new Text(text.join("")));
  
                const img = document.createElement("img");
                img.src = `/emojis/${emoji.productId}_${emoji.emojiId}.png`;
                img.width = size;
                img.height = size;
                img.style.setProperty('vertical-align', 'middle');
                img.style.setProperty('image-rendering', 'high-quality');
                // non-Retinaなモニターで画像がぼやける問題の対応
                img.style.setProperty('transform', 'translate3d(0, 0, 1px)');
                this.elTextMessage.appendChild(img);
  
                index = emoji.index + 1;
            });
            if (index < chars.length) {
              this.elTextMessage.appendChild(new Text(chars.slice(index, chars.length).join("")));
            }
            joinTitleFlag = 1
          }
        });
      },
      synchronizeEmoji() {
        this.messages.forEach((message,i) => {
          if(this.positionInfo[i]){
            this.createTextMessage();
          }
        });
      },
    },
    updated() {
      this.synchronizeEmoji();
    },
    mounted() {
      this.synchronizeEmoji();
    },
  });
  </script>
  
  <style>
  .balloon-left {
    margin: 10px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .balloon-left {
    margin-right: 20px;
  }
  
  .avatar {
    margin: 10px 20px 0 0;
  }
  
  .username {
    color: white;
    margin: 0 !important;
  }
  
  .text-preview {
    margin: 0 !important;
    font-size: small;
    max-width: 210px;
    display: inline-block;
    flex-wrap: wrap;
    position: relative;
    word-wrap: break-word;
    padding: 10px 15px 10px 15px;
    border-radius: 12px;
    background: white;
    box-sizing: border-box;
    line-height: 1.5;
    white-space: pre-line;
  }
  
  .image-preview {
    border-radius: 12px;
  }
  
  .text-preview:after {
    content: "";
    position: absolute;
    top: 10px;
    border: 10px solid transparent;
  }
  
  .balloon-left .text-preview:after {
    left: -20px;
    border-right: 22px solid white;
  }
  </style>
  
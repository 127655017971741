<style scoped>
/* @font-face {
  font-family: lucidaConsole;
  src: url("../../../../../assets/fonts/LUCON.TTF");
} */
/* .json-edit-area {
  white-space: nowrap;
  font-family: lucidaConsole, monospace;
} */
</style>
<template>
  <div>
    <v-textarea
      ref="textarea"
      class="mx-1 mt-4"
      placeholder="メッセージを入力して下さい"
      single-line
      outlined
      rows="4"
      hide-details="auto"
      v-model="text"
      :rules="rules"
      :disabled="!canEdit"
    ></v-textarea>
    <v-dialog v-model="show" class="emojiModal" width="600px" height="540px">
      <div class="emojiSelectHeader px-4 py-3">
        絵文字
        <v-icon @click="show=false">mdi-close</v-icon>
      </div>
      <v-card class="emojiHistory d-flex flex-wrap pa-3" flat>
        <div v-for="(emojiHistories,key,index) in emojiHistories" :key="index" style="position-relative">
          <button type="button" class="btn border-0 bg-transparent p-0 avatar avatar-xs m-2" @click="insertImage(emojiHistories.path), show=false">
            <v-img
              :src="baseUrl + emojiHistories.path"
            ></v-img>
          </button>
        </div>
      </v-card>
      <v-card class="emojiSelectInner d-flex flex-wrap" flat>
        <div v-for="(emojiPaths,key,index) in emojiPaths" :key="index" style="position-relative">
          <button type="button" class="btn border-0 bg-transparent p-0 avatar avatar-xs m-2" @click="insertImage(emojiPaths.path), show=false">
            <v-img
              :src="baseUrl + emojiPaths.path"
            ></v-img>
          </button>
        </div>
      </v-card>
    </v-dialog>
    <div class="d-flex mx-1" v-if="!flexUseFlg">
      <v-btn :color="textcolor" outlined elevation="0" @click="open" class="my-4 cancel-btn"> 絵文字 </v-btn>
      <div class="blue-grey--text my-4 mx-2 flex">
        <span style="font-size: 13px;" class="pr-5 d-inline-block">絵文字を挿入すると、数字やアルファベットが組み合わさった文字列がタグで表示されます。</span>
        <span style="font-size: 13px;" class="pr-5 d-block">
          実際の表示はプレビューでご確認下さい。
        </span>
        <span
          v-if="hasEmoji"
          style="font-size: 13px;"
          class="warning--text pr-5 d-inline-block">
            テキストメッセージ内に絵文字が埋め込まれています。プレビューより、絵文字の表示を確認してください。
        </span>
        <span
          v-if="hasInvalidEmoji || invalidEmojiTagPair"
          style="font-size: 13px;"
          class="warning--text pr-5 d-inline-block">
            絵文字埋め込み文字に誤りがある可能性があります。プレビューより、絵文字の表示を確認してください。
        </span>
        <span style="font-size: 13px;" class="error--text pr-5" :class="lengthErrorFlag ? 'd-inline-block' : 'u-d-none'">{{ overNumberOfCharactersCheck() }}</span>
      </div>
      <div class="blue-grey--text my-1" style="white-space: nowrap;">
        <span :class="lengthErrorFlag ? 'error--text': 'blue-grey--text'">{{ currentTextLength }} </span>/ {{ maxTextLength }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { MAX_EMOJIS_LENGTH_IN_TEXTMESSAGE, MAX_TEXTMESASAGE_LENGTH } from "@/store/modules/segments/segments.constants";
import emojis from "@/assets/emojis.json";
import { mapState, mapMutations } from "vuex";
import {
  SET_EMOJI_OVER_20_ERROR,
} from "@/store/mutation-types";

// タグの個数不一致カウント用
const EMOJI_START_REGEX = /<<\$/g;
const EMOJI_END_REGEX = /\$>>/g;

// text内の全てのemojiを取得する
// const ALL_EMOJI_REGEX = /<<\$([^(<<\$)|(\$>>)]*?)\$>>/g;
const ALL_EMOJI_REGEX = /<<\$([^(<<$)|($>>)]*?)\$>>/g;

// 正規っぽいのはhex24文字と数字3文字
const EMOJI_EXTRACT_REGEX = /<<\$([0-9a-f]{24}_[0-9]{3})\$>>/;

export default Vue.extend({
  props: {
    value: [Object, String],
    rules: Array,
    index: Number,
    passedByParentsPositionInfo: Array,
    messages: Array,
    flexUseFlg: Boolean,
    textcolor: String,
    canEdit: Boolean,
    title: String,
  },
  data() {
    return {
      text: "",
      currentTextLength: 0,
      errorText: false,
      show: false,
      baseUrl: process.env.BASE_URL,
      emojiPaths: emojis['paths'],
      emojisNames: emojis['fileNames'],
      positionInfo: {},
      emojiHistories:[],
      maxTextLength: MAX_TEXTMESASAGE_LENGTH,
      maxEmojiLength: MAX_EMOJIS_LENGTH_IN_TEXTMESSAGE,
      lengthErrorFlag: false,
      hasEmoji: false,
      hasInvalidEmoji: false,
      invalidEmojiTagPair: false,
    };
  },
  computed: {
    ...mapState({
      emojiOver20Error: state => state.emojiOver20Error,
    }),
  },
  mounted() {
    this.setEmojiHistories();
    this.restoreEmojiText();
  },
  watch:{
    text() {
      this.onChangeTextMessage();
    },
    emojiHistories(emojisArray) {
      localStorage.setItem("emojiHistoriesLS", JSON.stringify(emojisArray));
    },
    title() {
      this.onChangeTextMessage();
    },
  },
  methods: {
    ...mapMutations({
      setEmojiOver20Error: SET_EMOJI_OVER_20_ERROR,
    }),
    overNumberOfCharactersCheck() {
      const emojisLength = this.positionInfo.emojis ? this.positionInfo.emojis.length : 0 ;
      if(this.currentTextLength > this.maxTextLength && emojisLength > this.maxEmojiLength){
        this.lengthErrorFlag = true;
        this.setEmojiOver20Error(true);
        return "テキストは" + this.maxTextLength + "文字、絵文字は" + this.maxEmojiLength + "字以内で入力してください";
      }else if(this.currentTextLength > this.maxTextLength){
        this.lengthErrorFlag = true;
        this.setEmojiOver20Error(true);
        return "テキストは" + this.maxTextLength + "字以内で入力してください";
      }else if(emojisLength > this.maxEmojiLength){
        this.lengthErrorFlag = true;
        this.setEmojiOver20Error(true);
        return "LINE絵文字は" + this.maxEmojiLength + "字以内で入力してください";
      }else{
        this.lengthErrorFlag = false;
        this.setEmojiOver20Error(false);
      }
    },
    setEmojiHistories() {
      if (!this.emojiHistories.length && localStorage.getItem("emojiHistoriesLS")) {
        this.emojiHistories = JSON.parse(localStorage.getItem("emojiHistoriesLS"));
      }
    },
    restoreEmojiText() {
      if (this.passedByParentsPositionInfo && this.passedByParentsPositionInfo[this.index]){
        let text = this.passedByParentsPositionInfo[this.index].text;
        this.positionInfo = this.passedByParentsPositionInfo[this.index];
        
        let shift = 0;
        // 絵文字が存在するテキストの場合
        if ('emojis' in this.positionInfo) {
          this.positionInfo.emojis.forEach(({ index, productId, emojiId }) => {
            const emojiTag = `<<$${productId}_${emojiId}$>>`;
            const joinTitleIndex = this.messages.findIndex(item => item.type === "text");
            if(joinTitleIndex == this.index){
              text = text.substr(0, index - (this.title+'\n\n').length + shift) + emojiTag + text.substr(index - (this.title+'\n\n').length + shift + 1, text.length + shift);
            } else {
              text = text.substr(0, index + shift) + emojiTag + text.substr(index + shift + 1, text.length + shift);    
            }       
            // $をemojiTagに置換したのでemojiTagの長さ分shiftして次の$の位置を合わせる
            shift += emojiTag.length - 1;
          });
          this.text = text;
        } else {
          // 絵文字が存在しないテキストの場合
          this.text = text;
        }
        
      }
    },
    onChangeTextMessage() {
      const emulatedText = this.makePositionInfo();
      this.currentTextLength = emulatedText.length;
      const sendData = {
        positionInfo: this.positionInfo,
        lengthErrorFlag: this.lengthErrorFlag,
      }
      this.$emit("onChangeTextMessage", sendData, this.index);
    },
    open() {
      this.show = true;
    },
    insertImage(path) {
      if(!this.emojiHistories.find((obj) => obj.path === path)){
        this.emojiHistories.unshift({"path":path});
        if(this.emojiHistories.length > 10){
          this.emojiHistories.pop();
        }
      }

      const emojiTag = this.pathToEmojiTag(path);
      this.insertText(emojiTag);
    },
    pathToEmojiTag(path) {
      const emojiTagValue = path.split("/").pop().split(".").shift();
      return `<<$${emojiTagValue}$>>`;
    },
    insertText(text) {
      if (!text) {
        return;
      }

      if (typeof document.execCommand === "function") {
        // document.execCommandは確実にundo/redoできるが、MDNで非推奨なので存在するか確認してから使う
        this.$refs.textarea.$refs.input.focus();
        document.execCommand("insertText", false, text);
      } else {
        // fallback
        const input = this.$refs.textarea.$refs.input;
        const caretPos = input.selectionStart || 0;
        this.text = this.text.substr(0, caretPos) + text + this.text.substr(caretPos, this.text.length);
        this.$nextTick().then(() => {
          input.selectionStart = caretPos + text.length;
          input.selectionEnd = caretPos + text.length;
        });
      }
    },
    makePositionInfo() {
      const emojiPositions = [];
      const invalidEmojis = [];
      let titleLength = 0;
      const joinTitleIndex = this.messages.findIndex(item => item.type === "text");
      if (joinTitleIndex == this.index && (this.title != '' && this.title != null && this.title != undefined)) {
        titleLength = (this.title + '\n\n').length;
      }
      let text = this.text;
      let shift = 0;
      for (const allMatch of text.matchAll(ALL_EMOJI_REGEX)) {
        const matches = allMatch[0].match(EMOJI_EXTRACT_REGEX);
        if (!matches) {
          invalidEmojis.push(allMatch[0]);
          continue;
        }
        const emojiTag = matches[0];
        const emojiTagValue = matches[1];
        const { path } = emojis.paths.find(({ path }) => path === `emojis/${emojiTagValue}.png`) || {};
        if (!path) {
          invalidEmojis.push(allMatch[0]);
          continue;
        }
        emojiPositions.push({
          index: allMatch.index - shift + titleLength,
          path: `/${path}`,
        });

        const matchTextLength = emojiTag.length - 1;
        text = text.substr(0, allMatch.index - shift) + "$" + text.substr(allMatch.index + matchTextLength + 1 - shift, text.length);
        shift += matchTextLength;
      }

      const tagStartCount = text.match(EMOJI_START_REGEX)?.length || 0;
      const tagEndCount = text.match(EMOJI_END_REGEX)?.length || 0;

      // タグの開始/終了の個数不一致
      this.invalidEmojiTagPair = tagStartCount !== tagEndCount;

      // 不明な絵文字が存在 または タグが入れ子
      this.hasInvalidEmoji = invalidEmojis.length > 0 || (tagStartCount > 0 && tagEndCount > 0);

      this.hasEmoji = emojiPositions.length > 0;
      this.positionInfo = {
        text,
        emojis: emojiPositions,
      };

      return text;
    }
  },
});
</script>
<style>
.tag-image{
  cursor: grab;
}
.tag-image::selection{
  background: transparent;
}
.emojiModal{
  overflow:hidden;
}
.v-dialog.v-dialog--active{
  background: #fff!important;
}
</style>
<style scoped>
.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    padding: 1rem;
}
.mh-100 {
    max-height: 100% !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
.position-relative {
    position: relative !important;
}
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.5;
    border-radius: 2px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.border-0 {
    border: 0 !important;
}
.bg-transparent {
    background-color: transparent !important;
}
.p-0 {
    padding: 0 !important;
}
.avatar.avatar-xs {
    width: 2.25rem;
    height: 2.25rem;
}
.avatar {
    display: inline-block;
    position: relative;
    width: 4.5rem;
    height: 4.5rem;
    overflow: hidden;
    vertical-align: middle;
}
.m-2 {
    margin: 0.5rem !important;
}
.emojiSelectHeader{
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-bottom:solid 1px #edeff0;
}

.emojiSelectInner{
  overflow-x : hidden;
  border-radius:0;
  border-top:1px solid #dee2e6!important;
}
.wrapper.placeholder {
    pointer-events: none;
    position: absolute;
    top: 0.5rem;
    left: 0.75rem;
    padding-left: 2px;
    color: #adb5bd;
}
.editor{
    resize: vertical;
    overflow-y: auto;
    line-height: 1.58;
    white-space: pre-wrap;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(2.15625rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cfd4da;
    border-radius: 2px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

</style>
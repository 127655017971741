<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col cols="6">
        <v-card class="mx-auto py-8">
          <v-card-title class="mb-4">
            <v-row justify="center">
              表示する年度を選択して下さい
            </v-row>
          </v-card-title>
          <v-card-actions v-for="yearItem in yearItems" :key="yearItem.category_id">
            <v-container>
              <v-row justify="center">
                <v-col cols="10" class="py-0">
                  <v-btn
                    outlined
                    block
                    class="py-7"
                    @click="submit(yearItem.category_id)"
                  >
                    {{ yearItem.category_text }}<v-icon right class="select-year-icon">mdi-login</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import {
  SET_SEARCH_YEAR,
} from "@/store/mutation-types";
import {
} from "@/store/action-types";

import {
  YEAR_ITEMS,
} from "@/constants";

export default {
  name: 'SelectFiscalYear',
  data() {
    return {
      yearItems: YEAR_ITEMS(),
    };
  },
  computed: {
    ...mapState({
    }),
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
      setSearchYear: SET_SEARCH_YEAR,
    }),
    submit(fiscalYear) {
      // console.log('fiscalYear', fiscalYear)
      this.setSearchYear(fiscalYear);
      this.$router.push({
        name: "DeliveryList"
      });
    }
  },
  created() {
  },
}
</script>

<style scoped>
.select-year-icon {
  padding-top: 3px;
}
</style>
<template>
  <div class="contentsArea">
    <v-dialog v-if="createStaffGroupDialog" v-model="createStaffGroupDialog" width="600">
      <v-card>
        <v-card-title>グループ追加</v-card-title>
        <v-card-text>
          <v-text-field v-model="createStaffGroupName" label="グループ名" counter="10">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="createStaffGroupDialog = false">キャンセル</v-btn>
          <v-btn color="primary" @click="createStaffGroupNew()">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="isQrDialog" v-model="isQrDialog">
      <v-card style="height: 60%;">
        <v-card-title>
          LINE連携
        </v-card-title>
        <v-container style="height: 60%;">
          <v-row style="height: 600px;">
            <v-col cols="4">
              <v-card style="height: 100%;">
                <v-container fill-height>
                  <v-col cols="12">
                    <VueQrcode
                      style="position: absolute; left: 50%; margin-right: -50%;top: 50%;transform: translate(-50%, -50%);"
                      :value="qrCode" :options="{ width: 250 }" />
                  </v-col>
                </v-container>
              </v-card>
            </v-col>
            <v-col style="height: 100%;">
              <v-card style="height: 100%;" class="overflow-y-auto">
                <v-card-title>
                  QRコードの読み取りについて
                </v-card-title>

                <v-col>
                  <v-row>
                    <v-col>
                      1.[ホーム]＞[友だち追加]＞[QRコード]をタップします。
                    </v-col>
                    <v-col>
                      <img style="width: 300px; outline: solid;" cover src="@/assets/img/lineCodeReader-1.png" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row>
                    <v-col>
                      2.QRコードリーダーを開き、連携用QRコードをスキャンしてください。
                    </v-col>
                    <v-col>
                      <img style="width: 300px; outline: solid;" cover src="@/assets/img/lineCodeReader-2.png" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-card color="green lighten-4">
                    <v-card-title style="color: green;">QRコードリーダーの簡単起動</v-card-title>
                    <v-row>
                      <v-col>
                        <v-card-text>
                          ホームタブ／トークタブ／ニュースタブの検索窓にある[QRコード]マークや、
                          ホーム画面のLINEアイコンを長押しした際に出てくるメニューから簡単に起動することが出来ます。
                        </v-card-text>
                      </v-col>
                      <v-col>
                        <img style="width: 300px; outline: solid;" cover src="@/assets/img/lineCodeReader-3.png" />
                        <img style="width: 300px; outline: solid;" cover src="@/assets/img/lineCodeReader-4.png" />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="isQrDialog = false">閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="invalidDialog" v-model="invalidDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">職員無効化</span>
        </v-card-title>
        <v-card-text>
          「{{ invalidItem.last_name + ' ' + invalidItem.first_name }}」さんを無効化します。本当によろしいですか？<br>
          ※この操作は取り消せません。<br>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="errInvalidMsg">{{ errInvalidMsg }}</div>
          <v-btn :loading="loading" color="blue darken-1" text @click="invalidDialog = false, invalidItem = null">
            キャンセル
          </v-btn>
          <v-btn :loading="loading" color="blue darken-1" text @click="invalid(invalidItem)">
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="checkPasswordDialog" v-model="checkPasswordDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">登録完了</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            ※以下の登録情報を控えて下さい。<br>
            この画面は一度のみ表示されます。
            <v-container>
              <v-row>
                <v-col>
                  ユーザー名
                </v-col>
                <v-col>
                  <v-text-field readonly v-model="creatStaffRes.name"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  ログインID
                </v-col>
                <v-col>
                  <Transition name="slide-fade">
                    <p style="position:absolute" v-if="isCopyIdMsg">コピーしました!</p>
                  </Transition>
                  <v-text-field readonly v-model="creatStaffRes.id" @click:prepend-inner="copyId"
                    prepend-inner-icon="mdi-content-copy"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  初回パスワード
                </v-col>
                <v-col>
                  <Transition name="slide-fade">
                    <p style="position:absolute" v-if="isCopyPsMsg">コピーしました!</p>
                  </Transition>
                  <v-text-field readonly v-bind:type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" v-model="creatStaffRes.ps"
                    prepend-inner-icon="mdi-content-copy" @click:prepend-inner="copyPs"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" type="submit" color="primary" outlined @click="downloadCsv()">CSVエクスポート</v-btn>
          <v-btn :loading="loading" type="submit" color="green white--text"
            @click="checkPasswordDialog = false, creatStaffRes = null, snackbar = false, snackbarText = null">閉じる</v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="snackbar" multi-line>
        {{ snackbarText }}
      </v-snackbar>
    </v-dialog>
    <v-dialog v-if="updateDialog" v-model="updateDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">職員編集</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent v-model="isNotValid">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field :disabled="checkLoginUserIsUpdateUser" v-model="updateItem.last_name" label="職員氏名（姓）"
                    :rules="[required('職員氏名（姓）'), max_length_10, FullWidth]" counter="10">
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :disabled="checkLoginUserIsUpdateUser" v-model="updateItem.first_name" label="職員氏名（名）"
                    :rules="[required('職員氏名（名）'), max_length_10, FullWidth]" counter="10">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select :disabled="checkLoginUserIsUpdateUser" v-model="updateItem.authority"
                    :items="selectableAuthorities" :item-text="item => (item.authority_name)" item-value="authority_id"
                    label="権限" :rules="[required('権限')]">
                  </v-select>
                </v-col>
                <v-col>
                  <v-form @submit.prevent v-model="isNotValidEditAuthority" ref="editAuthority">
                    <div v-if="updateItem.authority == 4" class="d-flex">
                      <v-select :disabled="checkLoginUserIsUpdateUser" v-model="updateItem.classroom_id"
                        :items="classroomAll" :item-text="item => (item.school_grade + ' ' + item.school_class)"
                        item-value="classroom_id" label="担当クラス" align-content="center" align="center" no-gutters
                        :rules="[required('担当クラス')]">
                      </v-select>
                    </div>
                    <div v-if="updateItem.authority == 3" class="d-flex">
                      <v-select :disabled="checkLoginUserIsUpdateUser" v-model="updateItem.managed_grade"
                        :items="gradeAll" :item-text="item => (item.school_grade)" item-value="grade_id" label="担当学年"
                        align-content="center" align="center" no-gutters :rules="[required('担当学年')]">
                      </v-select>
                    </div>
                  </v-form>
                </v-col>
              </v-row>
              <v-row v-if="false">
                <v-col>
                  <!-- <v-text-field v-model="updateItem.mail" label="メールアドレス" -->
                     <!-- :rules="getMailValidationRules" counter="255"> -->
                  <!-- </v-text-field> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox :disabled="checkLoginUserIsUpdateUser" v-model="updateItem.is_driver" label="バスの運転手として登録する"
                    color="success" :value="1" hide-details></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select :disabled="checkLoginUserIsUpdateUser" v-model="updateItem.staff_group"
                    :items="allStaffGroupList" item-text="group_name" item-value="staff_group_id" label="グループ" multiple
                    chips>
                    <template v-slot:item="{ item }">
                      <div>{{ item.staff_group_id + ': ' + item.group_name }}</div>
                    </template>
                  </v-select>
                </v-col>
                <v-col v-if="false" cols="1" style="display: flex; align-items: center;">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab dark x-small elevation="1" color="primary" v-bind="attrs" v-on="on"
                        @click="openCreateStaffGroupDialog()">
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>グループ追加</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="errMsg">{{ errMsg }}</div>
          <v-btn :loading="loading" color="green" dark @click="updateDialog = false" outlined>キャンセル</v-btn>
          <v-btn :loading="loading" type="submit"
            :disabled="(!isNotValidEditAuthority || !isNotValid) || checkLoginUserIsUpdateUser" color="green white--text"
            @click="update()">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      show-select
      :loading="loading"
      :loading-text="loadingText"
      :no-data-text="noDataText"
      :no-results-text="noResultText"
      v-model="selected"
      :single-select="false"
      item-key="staff_id"
      :headers="headers"
      :items="staff"
      :search="search"
      sort-by="grade"
      class="elevation-1"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, -1], 'items-per-page-text': '行/ページ:' }"
    >
      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
        <v-simple-checkbox
          style="justify-content: left;"
          v-if="!(item.is_delete == 1)"
          :value="isSelected"
          @input="select($event)"
          color="primary"
        ></v-simple-checkbox>
      </template>
      <template v-slot:[`item.managed_grade`]="{ item }">
        {{ (item.authority == 3 && item.managed_grade) ? gradeIdToGradeValue(item.managed_grade) : (item.authority == 4 &&
          item.managed_class) ? gradeIdToGradeValue(item.managed_grade) : "ーー" }}
      </template>
      <template v-slot:[`item.managed_class`]="{ item }">
        {{ (item.authority == 3 && item.managed_grade) ? "全て" : (item.authority == 4 && item.managed_class) ?
          classIdToClassValue(item.managed_grade, item.managed_class) : "ーー" }}
      </template>
      <template v-slot:[`item.authority`]="{ item }">
        {{ item.authority ? convertAuthorityToJapanese(item.authority) : "ーー" }}
      </template>
      <!-- <template v-slot:[`item.updated_at`]="{ item }">
        {{ (item.updated_at) ? formatToYYYYMMDD(item.updated_at) : "ーー" }}
      </template> -->
      <template v-slot:[`item.created_at`]="{ item }">
        {{ (item.created_at) ? formatToYYYYMMDD(item.created_at) : "ーー" }}
      </template>
      <template v-slot:[`item.uid`]="{ item }">
        <div v-if="item.is_delete == 0">
          <v-col class="px-0">
            <v-btn v-if="item.uid == false" outlined color="green white--text" class="mr-2"
              @click="qrSetting(item)">LINE連携</v-btn>
            <v-btn v-else outlined color="blue white--text" class="mr-2"
              @click="openDeleteStaffIdRelationDialog(item)">連携解除</v-btn>
          </v-col>
        </div>
      </template>
      <template v-slot:[`item.editBtn`]="{ item }">
        <div v-if="item.is_delete == 0">
          <v-col align="center" class="px-0">
            <v-btn color="green white--text" class="mr-2" @click="openUpdateDialog(item)">編集</v-btn>
          </v-col>
        </div>
      </template>
      <template v-slot:[`item.deleteBtn`]="{ item }">
        <div v-if="item.is_delete == 0">
          <v-col align="center" class="px-0">
            <v-btn color="red white--text" class="mr-2" @click="openInvalidDialog(item)">無効</v-btn>
          </v-col>
        </div>
        <div v-else style="align-content: center;">
          <v-col align="center" class="px-0">
            <v-chip color="grey" text-color="white">
              無効
            </v-chip>
          </v-col>
        </div>
      </template>

      <!-- <template v-slot:[`item`]="{ item }">
        <tr :style="{ backgroundColor: (item.is_delete == 1) ? '#CCC' : 'transparent' }">
          <td>{{ item.staff_id }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.first_name }}</td>
          <td>{{ (item.authority == 3 && item.managed_grade) ? gradeIdToGradeValue(item.managed_grade) : (item.authority == 4 && item.managed_class) ? gradeIdToGradeValue(item.managed_grade) : "ーー" }}</td>
          <td>{{ (item.authority == 3 && item.managed_grade) ? "全て" : (item.authority == 4 && item.managed_class) ? classIdToClassValue(item.managed_grade, item.managed_class) : "ーー" }}</td>
          <td>{{ item.authority ? convertAuthorityToJapanese(item.authority) : "ーー" }}</td>
          <td>{{ (item.updated_at) ? formatToYYYYMMDD(item.updated_at) : "ーー" }}</td>
          <td>
            <div v-if="item.is_delete == 0">
              <v-col align="center" class="px-0">
                <v-btn v-if="item.uid == false" outlined color="green white--text" class="mr-2"
                  @click="qrSetting(item)">LINE連携</v-btn>
                <v-btn v-else outlined color="blue white--text" class="mr-2"
                  @click="openDeleteStaffIdRelationDialog(item)">連携解除</v-btn>
              </v-col>
            </div>
          </td>
          <td>
            <div v-if="item.is_delete == 0">
              <v-col align="center" class="px-0">
                <v-btn color="green white--text" class="mr-2" @click="openUpdateDialog(item)">編集</v-btn>
              </v-col>
            </div>
          </td>
          <td>
            <div v-if="item.is_delete == 0">
              <v-col align="center" class="px-0">
                <v-btn color="red white--text" class="mr-2" @click="openInvalidDialog(item)">無効</v-btn>
              </v-col>
            </div>
            <div v-else style="align-content: center;">
              <v-col align="center" class="px-0">
                <v-chip color="grey" text-color="white">
                  無効
                </v-chip>
              </v-col>
            </div>
          </td>
        </tr>
      </template> -->
      <template v-slot:[`footer.page-text`]="props">
        <div>全 {{ props.itemsLength }} 件中 {{ props.pageStart }} 件 〜 {{ props.pageStop }} 件を表示</div>
      </template>
      <template v-slot:no-data>
        データがありません
      </template>
      <template v-slot:top>
        <v-toolbar style="height: 100px;" flat class="titleBar">
          <v-row>
            <v-card-title>職員一覧</v-card-title>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :loading="loading" color="primary" class="mb-2 mr-4" outlined @click="reload">
                <v-icon left>
                  mdi-reload
                </v-icon>
                データ更新
              </v-btn>
              <v-btn :disabled="selected.length < 1" :loading="loading" color="primary" class="mb-2 mr-4" outlined @click="openDialogPdf()">
                LINE連携案内発行
              </v-btn>
              <v-btn :loading="loading" color="green" dark class="mb-2" @click="addItem">新規追加</v-btn>
            </v-card-actions>
          </v-row>
        </v-toolbar>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-text-field v-model="lastNameFilterValue" label="職員氏名（姓）">
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="firstNameFilterValue" label="職員氏名（名）">
              </v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete v-model="gradeFilterValue" label="担当学年" :items="gradeAll" item-text="school_grade"
                item-value="grade_id" clearable hide-details class="pl-3">
                <template v-slot:no-data>
                  <div class="px-4">データがありません</div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete v-model="classFilterValue" label="担当クラス" :items="classAll" item-text="school_class"
                item-value="class_id" clearable hide-details class="pl-3">
                <template v-slot:no-data>
                  <div class="px-4">データがありません</div>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-actions>

        <v-dialog v-if="dialog" v-model="dialog" :max-width="dialogMaxWidth">
          <v-card>
            <v-card-title>
              <span class="text-h4 font-weight-bold">新規追加</span>
            </v-card-title>
            <v-card-text>
              <v-form @submit.prevent v-model="isNotValid">
                <v-container>
                  <v-row>
                    <v-col>
                      <span class="text-h5 font-weight-bold">職員追加</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="false">
                    <v-col>
                      <v-text-field v-model="editedItem.staff.login_id" label="ログインID"
                        :rules="[required('ログインID'), min_length_5, max_length_15, half_alpha_numeric]" counter="15">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="editedItem.staff.last_name" label="職員氏名（姓）"
                        :rules="[required('職員氏名（姓）'), max_length_10, FullWidth]" counter="10">
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="editedItem.staff.first_name" label="職員氏名（名）"
                        :rules="[required('職員氏名（名）'), max_length_10, FullWidth]" counter="10">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select v-model="editedItem.staff.authority" :items="selectableAuthorities"
                        :item-text="item => (item.authority_name)" item-value="authority_id" label="権限"
                        :rules="[required('権限')]"></v-select>
                    </v-col>

                    <v-col>
                      <v-form @submit.prevent v-model="isNotValidEditAuthority" ref="addAuthority">
                        <div v-if="editedItem.staff.authority == 4" class="d-flex">
                          <v-select v-model="editedItem.staff.classroom_id" :items="classroomAll"
                            :item-text="item => (item.school_grade + ' ' + item.school_class)" item-value="classroom_id"
                            label="担当クラス" align-content="center" align="center" no-gutters
                            :rules="[required('担当クラス')]"></v-select>
                        </div>
                        <div v-if="editedItem.staff.authority == 3" class="d-flex">
                          <v-select v-model="editedItem.staff.managed_grade" :items="gradeAll"
                            :item-text="item => (item.school_grade)" item-value="grade_id" label="担当学年"
                            align-content="center" align="center" no-gutters :rules="[required('担当学年')]"></v-select>
                        </div>
                      </v-form>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="editedItem.staff.mail" label="メールアドレス"
                        :rules="getMailValidationRules" counter="255"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox v-model="editedItem.staff.is_driver" label="バス運転手として登録する" color="success" :value="1"
                        hide-details></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-if="false">
                    <v-col v-if="false">
                      <!-- 一時パスワード自動生成の為不要 -->
                      <v-text-field v-model="editedItem.staff.password" label="一時パスワード"
                        :rules="[required('一時パスワード')]"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select :no-data-text="noDataText" v-model="editedItem.staff.staff_group"
                        :items="allStaffGroupList" item-text="group_name" item-value="staff_group_id" label="グループ"
                        multiple chips>
                        <template v-slot:item="{ item }">
                          <div>{{ item.staff_group_id + ': ' + item.group_name }}</div>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col v-if="false" cols="1" style="display: flex; align-items: center;">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn fab dark x-small elevation="1" color="primary" v-bind="attrs" v-on="on"
                            @click="openCreateStaffGroupDialog()">
                            <v-icon dark>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>グループ追加</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <div v-if="errMsg">{{ errMsg }}</div>
              <v-btn :loading="loading" color="green" dark @click="dialog = false" outlined>キャンセル</v-btn>
              <v-btn :loading="loading" type="submit" :disabled="!isNotValid || !isNotValidEditAuthority"
                color="green white--text" @click="save(editedItem)">保存</v-btn>
            </v-card-actions> </v-card>
        </v-dialog>
        <v-dialog persistent v-model="deleteStaffIdRelationDialog" v-if="deleteStaffIdRelationDialog" max-width="500px">
          <v-card>
            <v-card-title>
              LINE連携解除
            </v-card-title>
            <v-card-text>
              LINEの連携を解除してもよろしいですか？<br>
              これにより職員は配信を受け取ることが出来なくなります。
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined :loading="loading" color="green" dark @click="closeDeleteStaffIdRelationDialog()">キャンセル</v-btn>
              <v-btn outlined :loading="loading" color="blue" dark @click="deleteStaffIdRetation()">連携解除</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">本当に下記の職員情報を削除しますか？</v-card-title>
            <v-card-text class="breakArea">
              {{ deleteTargetsName }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :loading="loading" color="blue darken-1" text @click="dialogDelete = false">キャンセル</v-btn>
              <v-btn :loading="loading" color="blue darken-1" text @click="deleting">削除</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogPDF" v-if="dialogPDF" max-width="600px">
          <v-card>
            <v-card-title>
              LINE連携案内発行
            </v-card-title>
            <v-card-text>
              職員に配布する学校連絡アプリのLINE連携案内を発行します。<br>
              「開始」ボタンを押してLINE連携案内の発行を開始して下さい。<br>
              ※発行に時間を要する場合があります。
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :loading="loading" color="primary" outlined @click="downloadPdf()">
                LINE連携案内発行
              </v-btn>
              <v-btn :loading="loading" color="primary" @click="dialogPDF = false">
                閉じる
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-btn :disabled="selected.length < 1" :loading="loading" color="primary" class="mb-2 mr-4" outlined @click="openDialogPdf()">
        LINE連携案内発行
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          読み込んでいます
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
Vue.component(VueQrcode.name, VueQrcode);
import { mapState, mapMutations, mapActions } from "vuex";
import {
  GET_STAFF_GROUP_ALL,
  CREATE_STAFF_GROUP,
  GET_STAFF_ALL,
  GET_STAFF_ALL_LENGTH,
  UPDATE_STAFF,
  CREATE_STAFF,
  INVALID_STAFF,
  GET_CLASSROOM_ALL,
  GET_GRADE_ALL,
  DELETE_STAFF,
  DELETE_STAFF_UID
} from "@/store/action-types";
import {
  SET_CREATED_STAFF,
  SET_DELETE_TARGETS,
  // SET_SEARCH_YEAR
} from "@/store/mutation-types";
import {
  AUTHORITIES,
  YEAR_ITEMS,
  LOADING_TEXT,
  REQUIRED_ERROR_MSG,
  MIN_LENGTH_5_ERROR_MSG,
  MAX_LENGTH_15_ERROR_MSG,
  MAX_LENGTH_30_ERROR_MSG,
  MAX_LENGTH_128_ERROR_MSG,
  MAX_LENGTH_319_ERROR_MSG,
  MAIL_ERROR_MSG,
  HALF_ALPHA_AND_NUM_ERROR_MSG,
  FULL_WIDTH_10_ERROR_MSG,
  NO_DATA_TEXT,
  NO_RESULT_TEXT,
MAX_LENGTH_255_ERROR_MSG
} from "@/constants";
import moment from 'moment';
import { saveAs } from "file-saver";
import Encoding from 'encoding-japanese';
import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import QRCode from 'qrcode';

export default {
  name: "StaffManage",
  data: () => ({
    isCopyIdMsg: false,
    isCopyPsMsg: false,
    createStaffGroupName: null,
    createStaffGroupDialog: false,
    allStaffGroupList: null,
    deleteStaffIdRelationItem: null,
    deleteStaffIdRelationDialog: false,
    qrCode: "",
    qrCodeDefault: "",
    // qrCodeDefault: "https://d68zpeernuz1h.cloudfront.net/staffIdRelation?",
    csvHeaders: [
      "管理画面URL",
      "職員名",
      "ログインID",
      "初回パスワード"
    ],
    isQrDialog: false,
    invalidItem: null,
    errInvalidMsg: null,
    invalidDialog: false,
    yearItems: YEAR_ITEMS(),
    // year :  moment().subtract(3, 'months').year(),
    authorities: AUTHORITIES,
    selectableAuthorities: AUTHORITIES.filter(auth => auth.authority_id !== 1),
    isNotValid: false,
    isNotValidEditAuthority: false,
    dialog: false,
    dialogDelete: false,
    dialogPDF: false,
    updateDialog: false,
    search: "",
    loadingText: LOADING_TEXT,
    noDataText: NO_DATA_TEXT,
    noResultText: NO_RESULT_TEXT,
    loading: false,
    searchItem: [
      {
        "text": "職員氏名（姓）",
        "value": "last_name"
      },
      {
        "text": "職員氏名（名）",
        "value": "first_name"
      },
    ],
    lastNameFilterValue: "",
    firstNameFilterValue: "",
    gradeFilterValue: "",
    classFilterValue: "",
    // mailFilterValue: "",
    selected: [],
    staff: [],
    editedIndex: -1,
    editedIndexList: [],
    itemTemplate: {
      "staff": {
        "login_id": "",
        "last_name": "",
        "first_name": "",
        "mail": "",
        "password": "",
        "managed_class": "",
        "managed_grade": "",
        "classroom_id": "",
        "authority": "",
        "is_driver": 0,
      },
    },
    editedItem: {
      "staff": {
        "login_id": "",
        "last_name": "",
        "first_name": "",
        "mail": "",
        "password": "",
        "managed_class": "",
        "managed_grade": "",
        "classroom_id": "",
        "authority": "",
        "is_driver": 0,
      }
    },
    updateItem: {},
    errMsg: null,
    defaultItem: {
      "login_id": "",
      "last_name": "",
      "first_name": "",
      "mail": "",
      "password": "",
      "managed_class": "",
      "managed_grade": "",
      "classroom_id": "",
      "authority": "",
      "is_driver": 0,
    },
    checkPasswordDialog: false,
    creatStaffRes: null,
    showPassword: false,
    snackbarText: null,
    snackbar: false,
    min_length_5: value => value.length >= 5 || MIN_LENGTH_5_ERROR_MSG,
    max_length_10: value => value.length <= 10 || FULL_WIDTH_10_ERROR_MSG,
    max_length_15: value => value.length <= 15 || MAX_LENGTH_15_ERROR_MSG,
    max_length_30: value => value.length <= 30 || MAX_LENGTH_30_ERROR_MSG,
    max_length_128: value => value.length <= 128 || MAX_LENGTH_128_ERROR_MSG,
    max_length_255: value => value.length <= 255 || MAX_LENGTH_255_ERROR_MSG,
    max_length_319: value => value.length <= 319 || MAX_LENGTH_319_ERROR_MSG,
    mailaddress: value => /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(value) || MAIL_ERROR_MSG,
    half_alpha_numeric: value => /^[a-zA-Z0-9]+$/.test(value) || HALF_ALPHA_AND_NUM_ERROR_MSG,
    FullWidth: v => /* eslint no-control-regex: off */ /[^\x01-\x7E\uFF61-\uFF9F]+$/.test(v) || FULL_WIDTH_10_ERROR_MSG,
  }),
  components: {
    VueQrcode
  },
  computed: {
    ...mapState({
      staffAll: state => state.staffAll,
      classroomAll: state => state.classroomAll,
      gradeAll: state => state.gradeAll,
      year: state => state.fiscalYear,
      loginUser: state => state.loginUser,
    }),
    gradeAll() {
      const gradeIdSet = new Set(); // 重複のないgrade_idを格納するためのSetを作成
      const gradeAll = [];
      for (const classroom of this.classroomAll) {
        const { grade_id, grade_name, grade_position, school_grade } = classroom;
        if (!gradeIdSet.has(grade_id)) {
          gradeAll.push({
            grade_id,
            grade_name,
            grade_position,
            school_grade
          });
          gradeIdSet.add(grade_id);
        }
      }
      return gradeAll
    },
    classAll() {
      let filtered = [...this.classroomAll];
      if (this.gradeFilterValue) { // 既に学年が選択されていれば、紐づくクラスのみ表示
        filtered = filtered.filter((c) => c.grade_id === this.gradeFilterValue);
      }

      const classAll = [];
      for (const classroom of filtered) {
        const { class_id, class_name, class_position, school_class } = classroom;
        classAll.push({
          class_id,
          class_name,
          class_position,
          school_class
        });
      }
      return classAll
    },
    checkLoginUserIsUpdateUser() {
      return (this.loginUser.staff_id == this.updateItem.staff_id)
    },
    dialogMaxWidth() {
      return "1100px";
    },
    deleteTargetsName() {
      let names = "";
      for (let i = 0; this.selected.length > i; i++) {
        names = names + this.selected[i].last_name + this.selected[i].first_name + "\n";
      }
      return names;
    },
    headers() {
      return [
        { text: "ログインID", value: "staff_id",width: "10%" },
        { text: "職員氏名（姓）", value: "last_name", filter: this.lastNameFilter ,width: "10%" },
        { text: "職員氏名（名）", value: "first_name", filter: this.firstNameFilter,width: "10%" },
        // { text: "メールアドレス", value: "mail", filter: this.mailFilter },
        { text: "担当学年", filter: this.gradeFilter, value: "managed_grade",width: "10%" },
        { text: "担当クラス", filter: this.classFilter, value: "managed_class",width: "10%" },
        { text: "権限", value: "authority", sortable: true, filter: this.authoritiesFilter,width: "10%" },
        // { text: "更新日", value: "updated_at", sortable: true },
        { text: "登録日時", value: "created_at", sortable: true ,width: "15%"},
        { text: "LINE連携", value: "uid", width: "20%"},
        { value: "editBtn", sortable: false ,width: '100px'},
        { value: "deleteBtn", sortable: false ,width: '100px'}
      ];
    },
    getMailValidationRules() {
    if ((this.editedItem && this.editedItem.staff && this.editedItem.staff.mail) || (this.updateItem && this.updateItem.mail)) {
        // 入力がある場合のバリデーションルールを返す
        return [this.max_length_255,this.mailaddress];
      } else {
        // 入力がない場合はバリデーションをスキップする
        return [];
      }
    },
    selectedValidStaffList() {
      return this.selected.filter((s) => !(s.is_delete));
    },
  },
  watch: {
    async isQrDialog(val) {
      console.log('isQrDialog', val)
      if (val == false) {
        this.loading = true;
        await this.getStaffAll();
        const staffAll = [...this.staffAll];
        this.staff = staffAll.filter(s => s.staff_id !== this.loginUser.staff_id && !s.is_delete); // ログイン中の職員を一覧に表示しない
        this.loading = false;
      }
    },
    'updateItem.classroom_id': {
      handler: function (v) {
        if (v != undefined && v != null && v != '') {
          this.updateItem.managed_grade = this.classroomAll.find(obj => obj.classroom_id === v)?.grade_id
          this.updateItem.managed_class = this.classroomAll.find(obj => obj.classroom_id === v)?.class_id
        }
      }
    },
    'editedItem.staff.classroom_id': {
      handler: function (v) {
        if (v != undefined && v != null && v != '') {
          this.editedItem.staff.managed_grade = this.classroomAll.find(obj => obj.classroom_id === v)?.grade_id
          this.editedItem.staff.managed_class = this.classroomAll.find(obj => obj.classroom_id === v)?.class_id
        }
      }
    },
    'updateItem.authority': {
      handler: function () {
        this.$refs.editAuthority.reset();
      }
    },
    'editedItem.staff.authority': {
      handler: function () {
        this.editedItem.staff.managed_class = null
        this.editedItem.staff.managed_grade = null
        this.$refs.addAuthority.reset();
      }
    },
    'updateItem.is_driver': {
      handler: function (v) {
        if (v === null) {
          this.editedItem.staff.is_driver = 0;
        }
      }
    },
    'editedItem.staff.is_driver': {
      handler: function (v) {
        if (v === null) {
          this.editedItem.staff.is_driver = 0;
        }
      }
    },
    gradeFilterValue: {
      handler: function () {
        this.classFilterValue = "";
      }
    },
    staffAll: {
      handler: function () {
        const staffAll = [...this.staffAll];
        this.staff = staffAll.filter(s => s.staff_id !== this.loginUser.staff_id && !s.is_delete); // ログイン中の職員を一覧に表示しない
      },
      deep: true
    },
    async year() {
      // await this.setSearchYear(val);
      await this.getStaffAll();
      await this.getClassroomItems();
      await this.getGradeItems();
      const staffAll = [...this.staffAll];
      this.staff = staffAll.filter(s => s.staff_id !== this.loginUser.staff_id && !s.is_delete); // ログイン中の職員を一覧に表示しない
    },
  },
  async created() {
    this.qrCode = 'https://liff.line.me/' + process.env.VUE_APP_STAFF_LOGIN_LIFF_ID + '?'
    this.qrCodeDefault = 'https://liff.line.me/' + process.env.VUE_APP_STAFF_LOGIN_LIFF_ID + '?'
    // this.qrCode = 'https://liff.line.me/1661206203-OWJ1gQG5?' // kawamitsu
    // this.qrCodeDefault = 'https://liff.line.me/1661206203-OWJ1gQG5?' // kawamitsu
    // this.qrCode = 'https://liff.line.me/1660889742-9MLBMdD8?' //開発用
    // this.qrCodeDefault = 'https://liff.line.me/1660889742-9MLBMdD8?' //開発用
    // this.qrCode = 'https://liff.line.me/1660995840-8mNOGkYJ?' //stg
    // this.qrCodeDefault = 'https://liff.line.me/1660995840-8mNOGkYJ?' //stg
    // this.qrCode = 'https://liff.line.me/1660889733-x0Z3GvNR?'  //本番用
    // this.qrCodeDefault = 'https://liff.line.me/1660889733-x0Z3GvNR?' //本番用

    this.loading = true;
    // await this.setSearchYear(this.year);
    await this.getClassroomItems(),
      await this.getGradeItems(),
      await this.getStaffGroup();

    // 職員の人数を取得する
    const getStaffAllLengthRes = await this.getStaffAllLength();

    let staffAllLength = 0
    if (getStaffAllLengthRes.statusCode) {
      if (getStaffAllLengthRes.statusCode == 200) {
        staffAllLength = getStaffAllLengthRes.body
      }
    }
    // studentsの人数が一致しない場合のみ取得処理を実行
    if (this.staffAll.length != staffAllLength) {
      await this.getStaffAll();
    }
    const staffAll = [...this.staffAll];

    console.log('staffAll', staffAll);


    this.staff = staffAll.filter(s => s.staff_id !== this.loginUser.staff_id && !s.is_delete); // ログイン中の職員を一覧に表示しないと削除済み職員（無効）は表示しない

    this.loading = false;
  },
  mounted() {
    // 全選択のラベル追加
    const elem = document.querySelector("tr i.v-icon.notranslate.mdi.mdi-checkbox-blank-outline.theme--light");
    if (elem) {
      const div = document.createElement('div');
      const textNode = document.createTextNode('全て');
      div.appendChild(textNode);
      div.style.fontSize = "12px";
      div.style.fontStyle = "normal";
      elem.appendChild(div);
    }
  },
  methods: {
    async reload() {
      this.loading = true;
      this.staff = []
      await this.getStaffAll();
      const staffAll = [...this.staffAll];
      this.staff = staffAll.filter(s => s.staff_id !== this.loginUser.staff_id && !s.is_delete); // ログイン中の職員を一覧に表示しない
      await this.getClassroomItems(),
        await this.getGradeItems(),
        await this.getStaffGroup();
      this.loading = false;
    },
    async createStaffGroupNew() {
      this.loading = true;
      await this.createStaffGroup(this.createStaffGroupName)
      const res = await this.getStaffGroupAll();
      if (res.body == '200') {
        this.allStaffGroupList = res.result.filter(obj => obj.is_delete != true);
      }
      this.createStaffGroupDialog = false;
      this.loading = false;
    },
    openCreateStaffGroupDialog() {
      this.createStaffGroupName = null;
      this.createStaffGroupDialog = true;
    },
    async getStaffGroup() {
      const res = await this.getStaffGroupAll();
      if (res.body == '200') {
        this.allStaffGroupList = res.result.filter(obj => obj.is_delete != true);
      }
    },
    async deleteStaffIdRetation() {
      this.loading = true;
      const res = await this.deleteStaffUid(this.deleteStaffIdRelationItem);
      console.log('res', res)
      this.deleteStaffIdRelationDialog = false;
      await this.getStaffAll();
      const staffAll = [...this.staffAll];
      this.staff = staffAll.filter(s => s.staff_id !== this.loginUser.staff_id && !s.is_delete); // ログイン中の職員を一覧に表示しない
      this.loading = false;
    },
    openDeleteStaffIdRelationDialog(item) {
      this.deleteStaffIdRelationItem = item;
      this.deleteStaffIdRelationDialog = true;
    },
    closeDeleteStaffIdRelationDialog() {
      this.deleteStaffIdRelationItem = null;
      this.deleteStaffIdRelationDialog = false;
    },
    async qrSetting(item) {
      // リセットしてからスタート
      this.qrCode = this.qrCodeDefault;
      this.qrCode =
        this.qrCode +
        "staffId=" + item.staff_id +
        "&pass=" + item.password;
      this.$nextTick(() => {
        this.showQr();
      });
    },
    showQr() {
      this.isQrDialog = true;
    },
    hiddenQr() {
      this.isQrDialog = false;
    },
    downloadCsv() {
      const data = [['https://' + location.host, this.creatStaffRes.name, this.creatStaffRes.id, this.creatStaffRes.ps]]

      const csvRows = [];
      const keys = Object.keys(data[0]);

      // ヘッダー行をCSVに追加
      csvRows.push(this.csvHeaders.join(','));

      // データ行をCSVに追加
      for (const row of data) {
        // console.log(row)
        const values = keys.map(key => {
          // console.log(key)
          return row[key];
        });
        // カンマ区切りの文字列にする
        let csvRow = values.join(',');
        // CSVデータに追加
        csvRows.push(csvRow);
      }
      // console.log(csvRows)
      // CSVをShift-JISにエンコード
      const csvString = csvRows.join('\r\n');
      const unicodeList = Encoding.stringToCode(csvString);
      const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'UNICODE');
      const shiftJisString = new Uint8Array(shiftJisCodeList);
      // CSVファイルをダウンロード
      const fileName = this.creatStaffRes.id + '_' + this.creatStaffRes.name + '.csv';
      const blob = new Blob([shiftJisString], { type: 'text/csv;charset=sjis' });
      saveAs(blob, fileName);
    },
    openInvalidDialog(item) {
      this.invalidItem = item;
      this.invalidDialog = true;
    },
    async copyId() {
      navigator.clipboard.writeText(this.creatStaffRes.id)
      this.isCopyIdMsg = true;
      await new Promise(resolve => setTimeout(resolve, 1500));
      this.isCopyIdMsg = false;
    },
    async copyPs() {
      navigator.clipboard.writeText(this.creatStaffRes.ps)
      this.isCopyPsMsg = true;
      await new Promise(resolve => setTimeout(resolve, 1500));
      this.isCopyPsMsg = false;
    },
    openUpdateDialog(item) {
      this.updateItem = { ...item }
      this.updateDialog = true
    },
    formatToYYYYMMDD(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    gradeIdToGradeValue(grade_id) {
      const obj = this.gradeAll.find(item => item.grade_id === String(grade_id));
      if (obj) {
        return obj.school_grade;
      } else {
        return 'ーー';
      }
    },
    classIdToGradeValue(classroom_id) {
      const obj = this.classroomAll.find(item => item.classroom_id === classroom_id);
      if (obj) {
        return obj.school_grade;
      } else {
        return 'ーー';
      }
    },
    classIdToClassValue(grade_id, class_id) {
      const obj = this.classroomAll.find(item => item.class_id === class_id && item.grade_id === grade_id);
      if (obj) {
        return obj.school_class;
      } else {
        return 'ーー';
      }
    },
    convertAuthorityToJapanese(authority_id) {
      let targetAuthority = this.authorities.find(authority => authority.authority_id === authority_id);
      if (targetAuthority) {
        return targetAuthority.authority_name
      } else {
        return 'ーー'
      }
    },
    sendStaffDetail(item) {
      return { name: 'StaffDetail', params: { item: item } }
    },
    ...mapActions({
      createStaffGroup: CREATE_STAFF_GROUP,
      getStaffGroupAll: GET_STAFF_GROUP_ALL,
      getStaffAll: GET_STAFF_ALL,
      getStaffAllLength: GET_STAFF_ALL_LENGTH,
      updateStaff: UPDATE_STAFF,
      createStaff: CREATE_STAFF,
      invalidStaff: INVALID_STAFF,
      getClassroomAll: GET_CLASSROOM_ALL,
      getGradeAll: GET_GRADE_ALL,
      deleteStaff: DELETE_STAFF,
      deleteStaffUid: DELETE_STAFF_UID
    }),
    ...mapMutations({
      setCreateStaff: SET_CREATED_STAFF,
      setDeleteTargets: SET_DELETE_TARGETS,
      // setSearchYear: SET_SEARCH_YEAR,
    }),
    deleting() {
      // ストアの削除対象を更新
      this.setDeleteTargets(this.selected);
      // 削除のアクションを叩く
      this.deleteStaff();
      this.dialogDelete = false;
      this.selected = [];
      this.$forceUpdate();
    },
    addItem() {
      this.editedItem = JSON.parse(JSON.stringify(this.itemTemplate));
      // this.editedItem = { ...this.itemTemplate };現在は問題ないですが、コメント残して置きます。
      this.dialog = true;
    },
    async invalid(item) {
      this.loading = true;
      await this.setCreateStaff(item);
      const res = await this.invalidStaff();
      if (res.statusCode != 200) {
        if (res.body == 'validationErr') {
          this.errMsg = '入力値が不正です。ページを再読み込みしてください。'
        } else {
          this.errMsg = '不明なエラーが発生しました。ページを再読み込みしてください。'
        }
      } else {
        this.invalidDialog = false;
        await this.getStaffAll();
        const staffAll = [...this.staffAll];
        this.staff = staffAll.filter(s => s.staff_id !== this.loginUser.staff_id && !s.is_delete); // ログイン中の職員を一覧に表示しない
      }
      this.invalidItem = null;
      this.loading = false;
    },
    async save(editItem) {
      let item = {...editItem}
      this.loading = true;
      if(item.staff.classroom_id != undefined && item.staff.classroom_id != null && item.staff.classroom_id != ''){
        item.staff.managed_grade = this.classroomAll.find(obj => obj.classroom_id === item.staff.classroom_id)?.grade_id
        item.staff.managed_class = this.classroomAll.find(obj => obj.classroom_id === item.staff.classroom_id)?.class_id
      }
      await this.setCreateStaff(item);
      const res = await this.createStaff();
      if (res.statusCode != 200) {
        if (res.body == 'validationErr') {
          this.errMsg = '入力値が不正です。ページを再読み込みしてください。'
        }
      } else {
        this.dialog = false;
        await this.getStaffAll();
        const staffAll = [...this.staffAll];
        this.staff = staffAll.filter(s => s.staff_id !== this.loginUser.staff_id && !s.is_delete); // ログイン中の職員を一覧に表示しない
        this.creatStaffRes = res.body
        this.checkPasswordDialog = true;
      }
      this.editedItem = null;
      this.loading = false;
    },
    async update() {
      this.loading = true;
      this.setCreateStaff(this.updateItem);
      const res = await this.updateStaff();
      if (res.statusCode != 200) {
        if (res.body == 'validationErr') {
          this.errMsg = '入力値が不正です。ページを再読み込みしてください。'
        }
      } else {
        this.updateDialog = false;
        await this.getStaffAll();
        const staffAll = [...this.staffAll];
        this.staff = staffAll.filter(s => s.staff_id !== this.loginUser.staff_id && !s.is_delete); // ログイン中の職員を一覧に表示しない
      }
      this.loading = false;
    },
    async getClassroomItems() {
      await this.getClassroomAll();
    },
    async getGradeItems() {
      await this.getGradeAll();
    },
    authoritiesFilter(v) {
      return v === 1 ? false : true;
    },
    lastNameFilter(value) {
      if (!this.lastNameFilterValue) {
        return true
      }
      return value.toLowerCase().includes(this.lastNameFilterValue.toLowerCase());
    },
    firstNameFilter(value) {
      if (!this.firstNameFilterValue) {
        return true
      }
      return value.toLowerCase().includes(this.firstNameFilterValue.toLowerCase());
    },
    gradeFilter(value) {
      if (!this.gradeFilterValue) {
        return true
      }
      if (!value) {
        return false
      }
      console.log('value', value)
      console.log('this.gradeFilterValue', this.gradeFilterValue)
      return value.includes(this.gradeFilterValue);
    },
    classFilter(value) {
      if (!this.classFilterValue) {
        return true
      }
      if (!value) {
        return false
      }
      console.log('value', value)
      console.log('this.classFilterValue', this.classFilterValue)
      return value.includes(this.classFilterValue);
    },
    // mailFilter(value) {
    //   if (!this.mailFilterValue) {
    //     return true
    //   }
    //   return value.toLowerCase().includes(this.mailFilterValue.toLowerCase());
    // },
    required(str) {
      return v => !!v && !this.isSpace(v) || str + REQUIRED_ERROR_MSG;
    },
    isSpace(v) {
      v = String(v)
      if (v == undefined || v == null) {
        return true
      }
      for (let i = 0; i < v.length; i++) {
        const code = v.charCodeAt(i);
        if (code === 0x3000) { // Unicodeのコードポイントを使って全角スペースを判定
          continue; // 全角スペースの場合はループを継続
        }
        if (code === 0x20) { // 半角スペースの場合はループを継続
          continue;
        }
        return false; // それ以外の文字が含まれている場合はfalseを返す
      }
      return true; // 全角スペースか半角スペースしか含まれていない場合はtrueを返す
    },
    openDialogPdf() {
      this.loading = true;
      this.dialogPDF = true
      this.loading = false;
    },
    async downloadPdf() {
      this.loading = true;
      // テンプレートPDFをバイト配列で読み込み
      const tempalteRaw = await fetch('/pdf/template_staff_line_relation.pdf').then((res) => res.arrayBuffer());

      // テンプレートPDFオブジェクトを用意
      const pdfDocTemp = await PDFDocument.load(tempalteRaw);
      pdfDocTemp.registerFontkit(fontkit);
      const fontRawTemp = await fetch('/fonts/Meiryo.ttf').then((res) => res.arrayBuffer());
      await pdfDocTemp.embedFont(fontRawTemp);

      // PDFオブジェクトを用意
      const pdfDoc = await PDFDocument.load(tempalteRaw);
      pdfDoc.registerFontkit(fontkit);
      const fontRaw = await fetch('/fonts/Meiryo.ttf').then((res) => res.arrayBuffer());
      const customFont = await pdfDoc.embedFont(fontRaw);

      for (let i = 0; i < this.selectedValidStaffList.length; i++) {
        // 2枚目以降はテンプレートPDFをページ追加
        if (i > 0) {
          const [pageTemp] = await pdfDoc.copyPages(pdfDocTemp, [0]);
          pdfDoc.addPage(pageTemp);
        }

        const pages = pdfDoc.getPages();
        const page = pages[i]; // 対象のページ

        const staff = this.selected[i]; // 対象の職員

        // QRコードの画像挿入
        this.qrCode = this.qrCodeDefault; // リセット
        this.qrCode = this.qrCode + "staffId=" + staff.staff_id + "&pass=" + staff.password;
        const canvas = document.createElement('canvas');
        let imageBytes = [];
        QRCode.toCanvas(canvas, this.qrCode, (error) => {
          if (error) throw error;
          const qrData = canvas.toDataURL(); // QRコードのデータURLを取得
          imageBytes = this.dataURLToByteArray(qrData); // バイト配列データを取得
        });
        const image = await pdfDoc.embedPng(imageBytes);
        page.drawImage(image, {
          x: 215, // 画像の左上隅のX座標
          y: 540, // 画像の左上隅のY座標
          width: 150, // 画像の幅
          height: 150, // 画像の高さ
        });

        // テキスト挿入
        page.drawText(`${staff.last_name}${staff.first_name}さん`, {
          x: 210,
          y: 700,
          size: 24,
          font: customFont
        });
      }

      // PDFダウンロード
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const fileName = moment().format("YYYYMMDD") + '_staffs.pdf';
      await saveAs(blob, fileName);
      this.loading = false;
    },
    dataURLToByteArray(dataURL) {
      // データURLをバイト配列に変換
      const base64 = dataURL.split(',')[1];
      const binaryString = atob(base64);
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      return byteArray;
    },
  },
}
</script>



<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.btnArea {
  display: flex;
}

.saveBtn {
  text-align: right;
}

.qrDialog .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.6);
}

.qrDialog .qrDialogArea {
  position: relative;
  z-index: 102;
  padding: 16px;
  background: #fff;
  border-radius: 16px;
}

.qrDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.titleBar {
  height: 120px;
}

.titleBar>div.v-toolbar__content {
  height: 120px !important;
}
</style>

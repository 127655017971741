<!-- <template>
  <v-navigation-drawer permanent absolute>
    <v-list dense nav>
      <v-list-item v-for="item in allowItems" :key="item.title" :to="(isDisabledMenu(item)) ? item.link : ''">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template> -->
<template>
  <v-navigation-drawer permanent absolute>
    <v-card style="height: 100%;">
      <v-list shaped dense nav>
        <v-list-item v-for="item in singleAllowItems" :key="item.title" :to="(isDisabledMenu(item)) ? item.link : ''" color="green">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list shaped dense nav class="mt-n3">
        <v-list-group v-for="item in enqueteItems" :key="item.title" v-model="item.active" :prepend-icon="item.action" color="green" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item class="list-item-area" v-for="child in item.child" :key="child.title" :to="(isDisabledMenu(child)) ? child.link : ''">
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-list shaped dense nav class="mt-n3" v-if="loginUser.authority != 5">
        <v-list-group v-for="item in allowItems" :key="item.title" v-model="item.active" :prepend-icon="item.action" color="green" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item class="list-item-area" v-for="child in item.child" :key="child.title" :to="(isDisabledMenu(child)) ? child.link : ''">
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px;">{{ child.title }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
  </v-navigation-drawer>
</template>


<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { AUTHORITIES } from "@/constants";

export default {
  data() {
    return {
      singleItems : [
        // { title: 'ホーム', icon: 'mdi-home', link: { name: 'HomeView' } },
        { title: 'メッセージ配信', icon: 'mdi-email', link: { name: 'DeliveryList' } },
        { title: '欠席/遅刻連絡', icon: 'mdi-face-mask', link: { name: 'AbsenceForm' } },
        { title: '送迎バス', icon: 'mdi-bus-marker', link: { name: 'BusDriverMenu' } },
      ],
      multiItems : [
        { title: '学校設定', icon: 'mdi-town-hall', link: { name: 'SchoolSettings' } },
        { title: '職員管理', icon: 'mdi-account-tie', link: { name: 'StaffManage' } },
        { title: '職員グループ管理', icon: 'mdi-account-group', link: { name: 'StaffGroupSettings' } }, 
        { title: '生徒管理', icon: 'mdi-human-child', link: { name: 'StudentManage' } },
        { title: 'カレンダー管理', icon: 'mdi-calendar-month-outline', link: { name: 'CalendarManage'}},
        { title: '送迎バス管理', icon: 'mdi-bus', link: { name: 'BusManage' } },
      ],
      allowItems: [
        {
          title: '設定',
          icon: 'mdi-cog',
          child:[]
        }
      ],
      enqueteItems: [
        { title: 'アンケート', icon: 'mdi-file-document-check', child:[] }
      ],
      enqueteSubItems : [
        { title: 'アンケート実施', icon: 'mdi-receipt-text-send', link: { name: 'EnqueteDeliveryList' } },
        { title: 'テンプレート一覧', icon: 'mdi-file-document-edit', link: { name: 'EnqueteList' } },
      ],
      singleAllowItems: [],
      authorities: AUTHORITIES,
    }
  },
  computed: {
    ...mapState({
      loginUser: (state) => state.loginUser,
      needCreateSchool: (state) => state.setup.needCreateSchool,
      needCreateStaff: (state) => state.setup.needCreateStaff,
      needCreateStudent: (state) => state.setup.needCreateStudent,
    }),
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),
    // メニューの閲覧が権限で許可されているかどうか
    isAllowMenu(menu_title) {
      if (menu_title === '送迎バス') {
        return (this.loginUser.is_driver === 1);
      } else {
        const authority_id = this.loginUser.authority;
        // console.log("authority_id", typeof authority_id);
        const authority = this.authorities.find((a) => a.authority_id === authority_id);
        // console.log("authority", authority);
        const menu = authority.allow_menus.find((m) => m === menu_title);
        return !!menu;
      }
    },
    isDisabledMenu(menu) {
      if(menu){
        return true
      } else {
        return true
      }

      // console.log('this.needCreateSchool', this.needCreateSchool)
      // console.log('this.needCreateStaff', this.needCreateStaff)
      // console.log('this.needCreateStudent', this.needCreateStudent)

      // // 学校情報が未作成なら、学校設定タブ以外非活性
      // if (this.needCreateSchool) {
      //   console.log('need create school')
      //   if (menu.title === "学校設定") {
      //     console.log('true', menu.title)
      //     return true;
      //   } else {
      //     console.log('false', menu.title)
      //     return false;
      //   }

      //   // 学校情報は作成済だが、職員管理もしくは生徒管理が未作成の場合
      // } else if (this.needCreateStaff || this.needCreateStudent) {
      //   console.log('need create staff or student')
      //   if (menu.title === "学校設定" || menu.title === "職員管理" || menu.title === "生徒管理") {
      //     console.log('true')
      //     return true;
      //   } else {
      //     console.log('false', menu.title)
      //     return false;
      //   }

      //   // 全て作成済み
      // } else {
      //   console.log('true', menu.title)
      //   return true;
      // }
    },
  },
  created() {
    this.allowItems[0].child = this.multiItems.filter((item) => this.isAllowMenu(item.title));
    this.enqueteItems[0].child = this.enqueteSubItems.filter((item) => this.isAllowMenu(item.title));
    this.singleAllowItems = this.singleItems.filter((item) => this.isAllowMenu(item.title));

    // 学校設定の利用プランに応じてメニューを表示
    let viewAbleMenu;
    let absenceFormFlag;
    if (Array.isArray(this.loginUser.schoolSettings.setting)) {
      viewAbleMenu = this.loginUser.schoolSettings.setting[0]?.viewAbleMenu
      absenceFormFlag = this.loginUser.schoolSettings.setting[0]?.absence_form
    }

    if (viewAbleMenu !== '' && viewAbleMenu !== null && viewAbleMenu !== undefined) {
      // absenceが無いもしくは外部リンクで利用する設定であれば、閲覧可能なメニューから欠席/遅刻連絡を省く
      if (!viewAbleMenu.includes('absence') || absenceFormFlag === 2) {
        this.singleAllowItems = this.singleAllowItems.filter(obj => obj.link.name !== 'AbsenceForm')
      }

      // calendarが無ければ、閲覧可能なメニューからカレンダー管理を省く
      if (!viewAbleMenu.includes('calendar')) {
        this.allowItems[0].child = this.allowItems[0].child.filter(obj => obj.link.name !== 'CalendarManage')
      }

      // enqueteが無ければ、閲覧可能なメニューからアンケートを省く
      if (!viewAbleMenu.includes('enquete')) {
        this.enqueteItems = []
      }

      // pickUpBusが無ければ、閲覧可能なメニューからアンケートを省く
      if (!viewAbleMenu.includes('pickUpBus')) {
        this.singleAllowItems = this.singleAllowItems.filter(obj => obj.link.name !== 'BusDriverMenu')
        this.allowItems[0].child = this.allowItems[0].child.filter(obj => obj.link.name !== 'BusManage')
      }
    } else {
      // viewAbleMenuがない場合は、すべて表示しない
      this.singleAllowItems = this.singleAllowItems.filter(obj => (obj.link.name !== 'AbsenceForm' && obj.link.name !== 'BusDriverMenu'))
      this.allowItems[0].child = this.allowItems[0].child.filter(obj => (obj.link.name !== 'CalendarManage' && obj.link.name !== 'BusManage'))
      this.enqueteItems = []
    }
  },
}
</script>
<style>
.list-item-area{
  padding-left: 16px !important;
}
.v-list-item__icon:first-child {
  margin-right: 14px !important;
}
</style>
<style scoped>
.v-list-item__content .v-list-item__title {
  font-size: 0.7rem;
}
</style>
export const SAVE_USER_NAME = "SAVE_USER_NAME";
export const SAVE_USER_PASSWORD = "SAVE_USER_PASSWORD";
export const SAVE_USER_LOGIN_TOKEN = "SAVE_USER_LOGIN_TOKEN";
export const LOGIN_TRUE = "LOGIN_TRUE";
export const LOGIN_FALSE = "LOGIN_FALSE";
export const GET_STUDENTS_ALL = "GET_STUDENTS_ALL";
export const GET_STUDENTS_ALL_LENGTH = "GET_STUDENTS_ALL_LENGTH";
export const CREATE_STUDENT = "CREATE_STUDENT";
export const INVALID_STUDENT = "INVALID_STUDENT";
export const GET_STAFF_GROUP_ALL = "GET_STAFF_GROUP_ALL";
export const DELETE_STAFF_GROUP = "DELETE_STAFF_GROUP";
export const CREATE_STAFF_GROUP = "CREATE_STAFF_GROUP";
export const UPDATE_STAFF_GROUP = "UPDATE_STAFF_GROUP";
export const INVALID_STAFF_GROUP = "INVALID_STAFF_GROUP";
export const GET_STAFF_DELIVERY_TARGET = "GET_STAFF_DELIVERY_TARGET";
export const UPDATE_STAFF_DELIVERY = "UPDATE_STAFF_DELIVERY";
export const GET_STAFF_ALL = "GET_STAFF_ALL"
export const CREATE_STAFF = "CREATE_STAFF";
export const INVALID_STAFF = "INVALID_STAFF";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const UPDATE_STAFF = "UPDATE_STAFF";
export const DELETE_STAFF_UID = "DELETE_STAFF_UID"
export const GET_CLASSROOM_ALL = "GET_CLASSROOM_ALL";
export const GET_GRADE_ALL = "GET_GRADE_ALL";
export const SAVE_DELIVERY_ITEM = "SAVE_DELIVERY_ITEM";
export const GET_ABSENCE_ALL = "GET_ABSENCE_ALL";
export const LOGIN_CHECK = "LOGIN_CHECK";
export const GET_STUDENT_BY_CLASSROOM_ID = "GET_STUDENT_BY_CLASSROOM_ID";
export const SAVE_DELIVERY_IMG_TO_S3 = "SAVE_DELIVERY_IMG_TO_S3";
export const LOGGED_TRUE = 'LOGGED_TRUE';
export const LOGGED_FALSE = 'LOGGED_FALSE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SEND_STAFF_DELIVERY = 'SEND_STAFF_DELIVERY';
export const SEND_DELIVERY = 'SEND_DELIVERY';
export const DELETE_STUDENTS = 'DELETE_STUDENTS';
export const DELETE_STAFF = 'DELETE_STAFF';
export const GET_DELIVERY_ALL = 'GET_DELIVERY_ALL';
export const GET_GRADE_CLASS_ALL = 'GET_GRADE_CLASS_ALL';
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY';
export const DELETE_DELIVERY = 'DELETE_DELIVERY';
export const SEND_STUDENT_CSV = 'SEND_STUDENT_CSV';
export const UPLOAD_MULTI_STUDENT = 'UPLOAD_MULTI_STUDENT';
export const CREATE_SCHOOL_ITEMS = 'CREATE_SCHOOL_ITEMS';
export const RESET_SCHOOL_ITEMS = 'RESET_SCHOOL_ITEMS';
export const FORCE_RESET_SCHOOL_ITEMS = 'FORCE_RESET_SCHOOL_ITEMS';
// export const GET_STUDENT_KEY = 'GET_STUDENT_KEY';
// export const UPDATE_STUDENT_SECURITY_KEY = 'UPDATE_STUDENT_SECURITY_KEY';
export const UPDATE_SCHOOL_ITEMS = 'UPDATE_SCHOOL_ITEMS';
// export const STUDENT_PDF_ISSUE = 'STUDENT_PDF_ISSUE';
// export const CREATE_STUDENT_SECURITY_KEY = 'CREATE_STUDENT_SECURITY_KEY';
export const GET_STAFF_ALL_LENGTH = 'GET_STAFF_ALL_LENGTH';
export const GET_DELIVERY_ALL_LENGTH = 'GET_DELIVERY_ALL_LENGTH';
export const GET_PARENTS_NAME = 'GET_PARENTS_NAME';
export const CREATE_STUDENT_PDF = 'CREATE_STUDENT_PDF';
export const DELETE_STUDENTS_PARENT = 'DELETE_STUDENTS_PARENT';
export const GET_SCHEDULE_ITEMS = 'GET_SCHEDULE_ITEMS';
export const DELETE_SCHEDULE_ITEMS = 'DELETE_SCHEDULE_ITEMS';
export const CREATE_SCHEDULE_ITEMS = 'CREATE_SCHEDULE_ITEMS';
export const UPDATE_SCHEDULE_ITEMS = 'UPDATE_SCHEDULE_ITEMS';
export const GET_STAFF_LIST_BY_STAFF_GROUP_ID = 'GET_STAFF_LIST_BY_STAFF_GROUP_ID'
export const DELETE_RELATION_STAFF_GROUP = 'DELETE_RELATION_STAFF_GROUP'
export const ADD_STAFF_LIST_STAFF_GROUP = 'ADD_STAFF_LIST_STAFF_GROUP'
export const GET_CURRENT_SCHOOL = 'GET_CURRENT_SCHOOL';
export const SEND_TEST_DELIVERY = 'SEND_TEST_DELIVERY';

/**
 * 送迎バス機能
 */
export const SAVE_BUS_ICON_TO_S3 = 'SAVE_BUS_ICON_TO_S3'; // バスアイコンをS3に保存
export const START_SEND_BUS_LOCATION = 'START_SEND_BUS_LOCATION'; // 走行開始
export const SEND_BUS_LOCATION = 'SEND_BUS_LOCATION'; // 位置情報送信
export const END_SEND_BUS_LOCATION = 'END_SEND_BUS_LOCATION'; // 走行終了
export const NOTIFICATION_TEAMS = 'NOTIFICATION_TEAMS'; // バスのログをTeamsに通知
export const GET_BUS_ALL = "GET_BUS_ALL"; // バス情報取得
export const GET_BUS_ALL_LENGTH = "GET_BUS_ALL_LENGTH"; // バス情報数取得
export const CREATE_BUS = "CREATE_BUS"; // バス情報作成
export const UPDATE_BUS = "UPDATE_BUS"; // バス情報更新
export const INVALID_BUS = "INVALID_BUS"; // バス情報無効化
export const DELETE_BUS = "DELETE_BUS"; // バス情報削除(※現在不使用)
export const GET_BUS_ROUTE_LIST = "GET_BUS_ROUTE_LIST"; // バスルート情報取得
export const GET_BUS_ROUTE_LENGTH = "GET_BUS_ROUTE_LENGTH"; // バスルート情報数取得
export const CREATE_BUS_ROUTE = "CREATE_BUS_ROUTE"; // バスルート情報作成
export const UPDATE_BUS_ROUTE = "UPDATE_BUS_ROUTE"; // バスルート情報更新
export const INVALIDATE_BUS_ROUTE = "INVALIDATE_BUS_ROUTE"; // バスルート情報無効化
export const CREATE_BUS_LOG = 'CREATE_BUS_LOG'; // バスのログ情報作成
export const GET_BUS_LOG_ALL = 'GET_BUS_LOG_ALL'; // バスのログ情報取得

/**
 * アンケート機能
 */
export const INIT_FORM_SCHEMA = "INIT_FORM_SCHEMA"; // テンプレート初期化 or 編集時のデータセット
export const REGISTER_SURVEY_CONFIG = "REGISTER_SURVEY_CONFIG"; // テンプレート登録
export const REGISTER_SURVEY_CONFIGS = "REGISTER_SURVEY_CONFIGS"; // テンプレート一括登録
export const UPDATE_SURVEY_CONFIG = "UPDATE_SURVEY_CONFIG"; // テンプレート更新
export const DELETE_SURVEY_CONFIGS = "DELETE_SURVEY_CONFIGS"; // テンプレート削除
export const FETCH_SURVEY_CONFIGS = "FETCH_SURVEY_CONFIGS"; // テンプレート一覧取得
export const REGISTER_ENQUETE_OPEN_INFO = "REGISTER_ENQUETE_OPEN_INFO"; // アンケート開催情報登録
export const CLOSE_TO_PUBLIC_ENQUETE_OPEN_INFO = "CLOSE_TO_PUBLIC_ENQUETE_OPEN_INFO"; // アンケート開催情報登録
export const FETCH_ENQUETE_OPNE_INFOS = "FETCH_ENQUETE_OPNE_INFOS"; // アンケート開催情報一覧取得
export const GET_ENQUETE_OPNE_INFO = "GET_ENQUETE_OPNE_INFO"; // アンケート開催情報取得
export const GET_ANALYSIS_ENQUETE_ANSWER = "GET_ANALYSIS_ENQUETE_ANSWER"; // アンケート回答結果集計結果

/**
 * 欠席連絡機能
 */
export const SEND_ABSENCE_FORM = 'SEND_ABSENCE_FORM';
export const GET_ABSENCE_FORM_ALL = 'GET_ABSENCE_FORM_ALL';
export const GET_ABSENCE_FORM_ALL_LENGTH = 'GET_ABSENCE_FORM_ALL_LENGTH';